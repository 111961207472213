import { graphql, useStaticQuery } from 'gatsby';

const useTeamData = () => {
	const { team } = useStaticQuery(
		graphql`
			query Team_DATA_QUERY {
				team: allMarkdownRemark(
					sort: { fields: [frontmatter___order], order: ASC }
					filter: { frontmatter: { templateKey: { eq: "team-member" } } }
				) {
					edges {
						node {
							id
							fields {
								slug
							}
							frontmatter {
								title
								templateKey
							}
						}
					}
				}
				
			}
		`
	);
	return team.edges;
};

export default useTeamData;
