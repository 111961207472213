import { graphql, useStaticQuery } from 'gatsby';

const useServicesData = () => {
	const { services } = useStaticQuery(
		graphql`
			query Services_DATA_QUERY {
				services: markdownRemark(
					frontmatter: { templateKey: { eq: "services-page" } }
				) {
					serviceFront: frontmatter {
						services {
							heading
						}
					}
					id
				}				
			}
		`
	);
	return services.serviceFront;
};

export default useServicesData;
