// copy of DF navbar-v3
// updating nav to use graphql data.
// use useStaticQuery

import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import useSiteMetadata from './hooks/SiteMetadata';
import useServicesData from './hooks/ServicesData';
import useTeamData from './hooks/TeamData';
// import useNavBarData from './hooks/NavBarData';
import { styled } from '@mui/system';
import theme from '../theme';

// MUI components
import MUILink from '@mui/material/Link';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// custom components
// import SubMenuItems from './SubMenuItem';
// import NuDialog from './NuDialog';
// import DSPTimetable from './DSP-Timetable';

const AppBarStyled = styled(AppBar)(({ theme }) => ({
	color: theme.palette.primary.main,
	top: '90px',
	zIndex: 10,
	marginBottom: '42px',
	// backgroundColor: 'transparent',
	[theme.breakpoints.up('xs')]: {
		display: `none`,
	},
	[theme.breakpoints.up('md')]: {
		display: 'flex',
		width: `100%`,
	},
	transition: 'all 0.5s ease',
}));

const MainToolbar = styled(Toolbar)(({ theme }) => ({
	justifyContent: 'center',
	transition: 'all 0.5s ease',
	'.MuiButtonBase-root': {
		borderRadius: 0,
		':hover': {
			transition: 'all 0.5s ease',
			// backgroundColor: 'rgba(255,255,255,0.3)',
			backgroundColor: 'rgba(255,255,255,0.05)',
			// color: theme.palette.primary.main,
		},
		'&.dialogButton': {
			fontSize: '1.2rem',
			textTransform: 'none',
			color: '#fff',
			width: 'auto',
			padding: '4px 26px',
		},
	},
	'& a': {
		color: theme.palette.primary.contrastText,
		// paddingTop: 10,
		'&:hover': {
			textDecoration: 'none',
		},
	},
	[theme.breakpoints.down('sm')]: {
		justifyContent: 'space-between',
	},
	[theme.breakpoints.up('xs')]: {
		minHeight: '36px',
	},
}));

const navItems = [
	{ path: '/', label: 'Home' },
	{ path: '/blog', label: 'Articles' },
	{
		path: '',
		label: 'Services',
		services: '{services}',
	},
	{
		path: '',
		label: 'Team',
		team: '{team}',
	},
	{ path: '/clinic-details', label: 'Clinic Details' },
	{ path: 'javascript:void(Tawk_API.toggle())', label: 'Contact Us' },
];

const NavBarComponent = (props) => {

	// const {services} = useNavBarData();
	// const team = useNavBarData();
	const {services} = useServicesData();
	const team  = useTeamData();

	const { container } = props;
	const { title, mainLogo } = useSiteMetadata();
	const [mobileOpen, setMobileOpen] = React.useState(false);

	const [anchorEl, setAnchorEl] = React.useState();

	const handleClick = (target, label) => {
		setAnchorEl({
			[label]: target,
		});
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<AppBarStyled position='sticky'>
				<MainToolbar>
					{/* <Link to='/'>
						<img
							src={mainLogo}
							alt={title}
							style={{ width: '88px', marginRight: '20px' }}
						/>
					</Link> */}

					<List sx={{ display: { sm: 'none', md: 'flex' }, height: '100%', py: 0 }}>
						{navItems &&
							navItems.map((navItem) => (
								<ListItem
									sx={{
										p: 0,
										color: '#fff',
										'&:hover': { backgroundColor: 'rgba(0,0,0,0.26)' },
									}}
								>
									<Button
										id='basic-button'
										// key={navItem.path}
										aria-controls={
											anchorEl && Boolean(anchorEl[navItem.label])
												? 'basic-{navItem.label}'
												: undefined
										}
										aria-haspopup='true'
										aria-expanded={
											anchorEl && Boolean(anchorEl[navItem.label]) ? 'true' : undefined
										}
										onClick={(event) => handleClick(event.currentTarget, navItem.label)}
										sx={{
											justifyContent: 'center',
											width: 'max-content',
											color: '#fff',
											py: 2,
											px: 3,
										}}
										href={navItem.path}
									>
										{navItem.label ? (
											<Typography
												component='span'
												sx={{
													padding: '0 1vw',
													m: 0,
													fontWeight: 'initial',
													color: '#FFF',
												}}
											>
												{navItem.label}
											</Typography>
										) : null}
									</Button>
									<>
										{navItem.team ? (
											<Menu
												key={`${navItem.label}-subs`}
												id={`basic-${navItem.label}`}
												anchorEl={anchorEl && anchorEl[navItem.label]}
												open={anchorEl && Boolean(anchorEl[navItem.label])}
												onClose={handleClose}
												MenuListProps={{
													'aria-labelledby': 'basic-button',
												}}
												sx={{ '.MuiPaper-root': { backgroundColor: '#fff' } }}
											>
												{team?.map(({ node: member, i }) => (
													<MenuItem
														key={`${member.id}-sub-${i}`}
														onClick={handleClose}
														component={Link}
														to={member.fields.slug}
														sx={{ fontSize: 'initial' }}
													>
														{member.frontmatter.title}
													</MenuItem>
												))}
											</Menu>
										) : navItem.services ? (
											<Menu
												key={`${navItem.label}-subs`}
												id={`basic-${navItem.label}`}
												anchorEl={anchorEl && anchorEl[navItem.label]}
												open={anchorEl && Boolean(anchorEl[navItem.label])}
												onClose={handleClose}
												MenuListProps={{
													'aria-labelledby': 'basic-button',
												}}
												sx={{ '.MuiPaper-root': { backgroundColor: '#fff' } }}
											>
												{services.map((service, i) => (
													<MenuItem
														key={`${service.heading}-sub-${i}`}
														onClick={handleClose}
														sx={{ fontSize: 'initial' }}
													>
														<MUILink  href={`/#${service.heading}`}>
															{service.heading}
														</MUILink>
													</MenuItem>
												))}
											</Menu>
										) : null}
									</>
								</ListItem>
							))}
					</List>
					{/* <NuDialog buttonText='Book Classes' variant='text' title='Book Classes'>
						<DSPTimetable />
					</NuDialog> */}
				</MainToolbar>
			</AppBarStyled>

			{/* removed the DrawerNav and duplicated the menuitem array in the social header so there wasn't 2 drawers.
			need to put the menu items separate somewhere and call them from the DB. */}
		</>
	);
};

export default NavBarComponent;
